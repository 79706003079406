'use strict';
var $ = global.jQuery = require('jquery');

// Required Libraries
var matchHeight = require('jquery-match-height');
require('slick-carousel');
require('bootstrap-sass');

var styles = [{
    "featureType": "administrative",
    "elementType": "all",
    "stylers": [{
        "hue": "#000000"
      },
      {
        "lightness": -100
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [{
        "saturation": "-100"
      },
      {
        "lightness": "-10"
      },
      {
        "visibility": "on"
      },
      {
        "hue": "#30ff00"
      },
      {
        "gamma": "1.34"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "labels",
    "stylers": [{
        "hue": "#000000"
      },
      {
        "saturation": -100
      },
      {
        "lightness": -100
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [{
      "visibility": "off"
    }]
  },
  {
    "featureType": "poi.business",
    "elementType": "all",
    "stylers": [{
      "saturation": "-100"
    }]
  },
  {
    "featureType": "poi.business",
    "elementType": "geometry.fill",
    "stylers": [{
      "hue": "#00ff78"
    }]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [{
        "saturation": -100
      },
      {
        "lightness": 26
      },
      {
        "visibility": "on"
      },
      {
        "weight": "7.55"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels",
    "stylers": [{
        "saturation": -100
      },
      {
        "lightness": 100
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [{
        "visibility": "on"
      },
      {
        "color": "#414141"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "all",
    "stylers": [{
      "visibility": "on"
    }]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [{
      "color": "#525252"
    }]
  },
  {
    "featureType": "road.local",
    "elementType": "all",
    "stylers": [{
        "hue": "#ffffff"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 100
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [{
        "color": "#696969"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels",
    "stylers": [{
        "hue": "#000000"
      },
      {
        "lightness": -100
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [{
        "hue": "#ffffff"
      },
      {
        "saturation": -100
      },
      {
        "lightness": 100
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [{
        "hue": "#000000"
      },
      {
        "saturation": -100
      },
      {
        "lightness": -100
      },
      {
        "visibility": "off"
      }
    ]
  }
];

var melt = {
  el: {
    vw: undefined,
    vh: undefined
  },
  init: function() {
    getBrowserDimensions();
  }
}


// Base Functions
function getBrowserDimensions() {
  melt.el.vw = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
  melt.el.vh = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
}

function initMap() {
  if ($('#map').length) {
    var meltMap = { lat: 32.730426, lng: -97.337902 };
    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 18,
      center: meltMap,
      styles: styles
    });
    var image = {
      url: window.location.origin + '/assets/img/map-pin.png',
      size: new google.maps.Size(74, 86),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(50, 86)
    };
    var marker = new google.maps.Marker({
      position: meltMap,
      map: map,
      icon: image
    });
  }

}
var $flavorSlider = $('#flavor-slider');

function setupNumbers() {
  $flavorSlider.find('.slide').each(function(index) {
    $(this).find('.num').text(index + 1)
  });
}

function initSlider() {
  if ($flavorSlider.length) {
    $flavorSlider.slick({
      slidesToShow: 3,
      infinite: true,
      responsive: [{
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
}




// $('#contact-form').on('submit', function(e) {
//   e.preventDefault();
//   var formSpreeID = $('#formspree-id').text(),
//     ccEmails = $('#cc-emails').text(),
//     d = new Date(),
//     now = new Date(Date.now()),
//     time = now.getHours() + ":" + now.getMinutes(),
//     formData = $(this).serializeArray();

//   $.ajax({
//     url: "https://formspree.io/" + formSpreeID,
//     method: "POST",
//     data: {
//       _subject: 'MMS Website Inquiry - ' + d.toLocaleDateString() + ' ' + time,
//       _cc: ccEmails,
//       name: formData[0].value + ' ' + formData[1].value,
//       company: formData[4].value,
//       phone: formData[3].value,
//       _replyto: formData[2].value,
//       comments: formData[5].value
//     },
//     dataType: "json",
//     error: function(err) {
//       console.log(err)
//     }
//   });

// })

// Scroll Functions

var isStuck = false;

$(function() {
  $(window).scroll(function() {
    if (melt.el.vw > 767) {
      if ($('main').hasClass('home')) {
        // Navigation
        var scrollTop = $(window).scrollTop(),
          divOffset = $('section.flavors').offset().top - 155,
          dist = (divOffset - scrollTop);

        if ((scrollTop >= divOffset) && !isStuck) {
          $('nav').addClass('fixed')
          $('section.flavors').css('padding-top', 270);
          isStuck = true;
        } else if (isStuck && ((scrollTop - 155) <= divOffset)) {
          $('nav').removeClass('fixed');
          $('section.flavors').css('padding-top', 115);
          isStuck = false;
        }
      }
    }

  });
});



// Resize Function
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}


// On Resize
var throttleResizing = debounce(function() {

  getBrowserDimensions();

}, 200);
if (window.addEventListener) {
  window.addEventListener('load', throttleResizing);
  window.addEventListener('resize', throttleResizing);
} else {
  // Browsers that need to die
  window.attachEvent('onLoad', throttleResizing);
  window.attachEvent('onResize', throttleResizing);
}


// Init
$(document).ready(function() {
  melt.init();
  initMap();
  setupNumbers();
  initSlider();
});

global.melt = melt;